<template>
  <BaseToolbarSelect
    :title="selectTitle"
    :items="availableThemes"
    :active="activeTheme"
    @change="changed"
  />
</template>

<script>
export default {
  name: "ThemeSelect",
  components: {
    BaseToolbarSelect: () => import("@/components/BaseToolbarSelect.vue")
  },
  props: {
    active: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      availableThemes: [
        {
          name: this.$t("components.tei.editor.theme.blackboard"),
          value: "blackboard"
        },
        {
          name: this.$t("components.tei.editor.theme.base16-light"),
          value: "base16-light"
        },
        {
          name: this.$t("components.tei.editor.theme.eclipse"),
          value: "eclipse"
        },
        {
          name: this.$t("components.tei.editor.theme.pastel"),
          value: "pastel-on-dark"
        }
      ],
      activeTheme: this.active || ""
    };
  },
  computed: {
    selectTitle() {
      let activeName = this.themeName(this.activeTheme);
      return (
        this.$t("components.tei.theme") + (activeName ? ": " + activeName : "")
      );
    }
  },
  methods: {
    changed: function(theme) {
      // Set reactive value activeTheme to make ThemeSelect update itself
      this.activeTheme = theme.value;
      this.$emit("change", { name: theme.name, theme: theme.value });
    },
    // Return the display name matching this internal theme name
    themeName(themeValue) {
      for (let theme of this.availableThemes) {
        if (themeValue === theme.value) {
          return theme.name;
        }
      }
      return null;
    }
  }
};
</script>

<style></style>
